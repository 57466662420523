import axios from 'axios'
import { backToLogin, checkApiUrl } from '../helpers';
import {stringify} from 'query-string';
import moment from 'moment/moment';
import _ from 'lodash';

axios.interceptors.response.use(res => {
    if (!res.data.success && res.data.errors && _.isArray(res.data.errors)) {
        const isNotAuth = res.data.errors.some(error => {
            return error.message === 'You are not authorized to access this action' || error.message === 'not authorized with crowd';
        });

        if (isNotAuth) {
            localStorage.clear();
            backToLogin();
        }
    }
    return res;
});

export const getAllUsers = (accessToken) => {
    return axios.get(`${checkApiUrl()}/api/users?is_active=1&roles[]=ADMIN&roles[]=DEV&roles[]=FIN&roles[]=SALES&roles[]=PM&limit=1000` , {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const getClients = (query, accessToken, clientToken) => {
    return axios.get(`${checkApiUrl()}/core-api/v1/client?${stringify(query, {arrayFormat: 'comma'})}`, {
        headers: {
            "skynix-access-token": accessToken,
            "skynix-access-key": clientToken,
        }
    });
}

export const getClientToken = (accessToken) => {
    return axios.get(`${checkApiUrl()}/api/client-token`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const putClient = ({id, name, email, first_name, last_name}, clientToken) => {
    return axios.put(`${checkApiUrl()}/core-api/client/${id}`, {
            name,
            first_name,
            last_name,
            email
        },
        {
            headers: {
                "skynix-access-key": clientToken,
            }
        })
}

export const deleteClient = (client_id, clientToken) => {
    return axios.delete(`${checkApiUrl()}/core-api/client/${client_id}`, {
        headers: {
            "skynix-access-key": clientToken,
        }
    })
}

export const getClientModules = (clientId, accessToken, clientToken) => {
    return axios.get(`${checkApiUrl()}/core-api/client/${clientId}/modules`, {
        headers: {
            "skynix-access-key": clientToken,
            "skynix-access-token": accessToken,
        }
    })
}

export const getModules = (accessToken, clientToken) => {
    return axios.get(`${checkApiUrl()}/core-api/modules`, {
        headers: {
            "skynix-access-key": clientToken,
            "skynix-access-token": accessToken,
        }
    })
}

export const postRemoveClientModules = (moduleId, clientId, accessToken, clientToken) => {
    return axios.post(`${checkApiUrl()}/core-api/client/${clientId}/module/${moduleId}/remove`, {

    },{
        headers: {
            "skynix-access-token": accessToken,
            "skynix-access-key": clientToken,
        }
    })
}

export const getAllModules = (accessToken, clientToken) => {
    return axios.get(`${checkApiUrl()}/core-api/modules`, {
        headers: {
            "skynix-access-key": clientToken,
            "skynix-access-token": accessToken,
        }
    })
}

export const postAddModule = (client_id, module_id, accessToken, clientToken) => {
    return axios.post(`${checkApiUrl()}/core-api/client/${client_id}/module/${module_id}/add`, {
        client_id,
        module_id,
    }, {
        headers: {
            "skynix-access-key": clientToken,
            "skynix-access-token": accessToken,
        }
    })
}

export const getMySalaryHistory = (query, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/v1/users/salaries_history?${stringify(query, {arrayFormat: 'comma'})}`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const getMyInvoices = (query, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/subcontractor-invoices?${stringify(query, {arrayFormat: 'comma'})}`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const postMyInvoice = (value, accessToken) => {
    return axios.post(`${checkApiUrl()}/api/subcontractor-invoices`, {
        ...value,
        user_id: value.user_id.value,
        date_to: moment(value.date_to).format("YYYY-MM-DD"),
        date_from: moment(value.date_from).format("YYYY-MM-DD")
    }, {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const deleteMyInvoice = (id, accessToken) => {
    return axios.delete(`${checkApiUrl()}/api/subcontractor-invoices/${id}`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const getSalaryReportPdfFile = (id, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/salary-reports/${id}`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const getSalaryReportSpreadsheet = (id, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/salary-report-spreadsheet/${id}`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const getMyInvoicesPdfFile = (id, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/subcontractor-invoices/${id}`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const getAllSales = (accessToken) => {
    return axios.get(`${checkApiUrl()}/api/users?role=SALES`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const postSubcontractor = (params, accessToken) => {
    return axios.post(`${checkApiUrl()}/api/subcontractors`,
        params,
      {
          headers: {
              'skynix-access-token': accessToken,
          },
      });
};

export const getSubcontractor = (id, query, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/v1/financial-reports/${id}/subcontractors?${stringify(query, {arrayFormat: 'comma'})}`,
      {
        headers: {
            'skynix-access-token': accessToken,
        },
    });
};

export const deleteSubcontractor = (id_subcontrator, accessToken) => {
    return axios.delete(`${checkApiUrl()}/api/subcontractors/${id_subcontrator}`, {
        headers: {
            'skynix-access-token': accessToken,
        },
    });
};

export const putSubcontractor = (id_subcontrator, financial_report_id, user_owner_id, title, amount, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/subcontractors/${id_subcontrator}`, {
          financial_report_id,
          user_owner_id,
          title,
          amount,
      },
      {
          headers: {
              'skynix-access-token': accessToken,
          },
      });
};

export const getProjects = (query, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/projects?${stringify(query, {arrayFormat: 'comma'})}`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const postProject = ({
    date_end,
    date_start,
    developers,
    selectInvoiceReceiver,
    selectPM,
    selectSales,
    jira_code,
    project_name,
    selectStatus,
    type,
    customers,
    is_internal
}, accessToken) => {
    return axios.post(`${checkApiUrl()}/api/projects`, {
        date_end: date_end.split("/").reverse().join("-"),
        date_start: date_start.split("/").reverse().join("-"),
        developers,
        customers,
        invoice_received: +selectInvoiceReceiver,
        is_pm: +selectPM,
        is_sales: +selectSales,
        jira_code,
        name: project_name,
        status: selectStatus,
        type,
        is_internal: is_internal ? 1 : 0
      },{
        headers: {
            'skynix-access-token': accessToken,
        },
    })
}

export const putEditProject = (id, {
        date_end,
        date_start,
        developers,
        invoice_received,
        is_pm,
        is_sales,
        jira_code,
        project_name,
        selectStatus,
        type,
        milestones_name,
        start_date,
        end_date,
        estimated_amount,
        customers
    }, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/projects/${id}`, {
        date_end: date_end.split("/").reverse().join("-"),
        date_start: date_start.split("/").reverse().join("-"),
        developers,
        customers,
        invoice_received,
        is_pm,
        is_sales,
        jira_code,
        name: project_name,
        status: selectStatus,
        type,
        milestones: [{name: milestones_name, start_date, end_date, estimated_amount}]
    },{
        headers: {
            'skynix-access-token': accessToken,
        },
    })
}

export const deleteProject = (id, accessToken) => {
    return axios.delete(`${checkApiUrl()}/api/projects/${id}`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const getProjectByIdMonitoringServices = (id, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/projects/${id}/monitoring-services`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const postProjectByIdMonitoringServices = (id, {url, notification_emails}, accessToken) => {
    return axios.post(`${checkApiUrl()}/api/projects/${id}/monitoring-services`, {
        url,
        notification_emails
      },{
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const deleteProjectByIdMonitoringServices = (id, monitoring_id, accessToken) => {
    return axios.delete(`${checkApiUrl()}/api/projects/${id}/monitoring-services/${monitoring_id}`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const putToggleMonitoringServices = (id, monitoring_id, is_enabled, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/projects/${id}/monitoring-services/${monitoring_id}`, {
        is_enabled
      },{
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const getProjectById = (id, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/projects?id=${id}`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const getSearchProjects = (param, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/projects?search_query=${param}`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const getProjectId = (year, id, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/statistics/projects?year=${year}&project_id=${id}`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const getEnvironments = (project_id, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/projects/${project_id}/env`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const postAddNewEnvironments = (project_id, branch, roles, accessToken) => {
    return axios.post(`${checkApiUrl()}/api/projects/${project_id}/env`, {
        branch,
        roles
    },
    {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const updateEnvironments = (project_id, env_id, branch, roles, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/projects/${project_id}/env/${env_id}`, {
          branch,
          roles
      },
      {
          headers: {
              "skynix-access-token": accessToken,
          }
      })
}

export const deleteEnvironments = (project_id, env_id, accessToken) => {
    return axios.delete(`${checkApiUrl()}/api/projects/${project_id}/env/${env_id}`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const getVariables = (project_id, env, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/projects/${project_id}/env/${env}`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const postAddNewVariables = (project_id, env, key, value, accessToken) => {
    return axios.post(`${checkApiUrl()}/api/projects/${project_id}/env/${env}`, {
        key,
        value
    },
    {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const putEditVariable = (project_id, env, variable_id, key, value, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/projects/${project_id}/env/${env}/${variable_id}`, {
        key,
        value
    },
    {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const deleteVariable = (project_id, env, variable_id, accessToken) => {
    return axios.delete(`${checkApiUrl()}/api/projects/${project_id}/env/${env}/${variable_id}`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const postShareVariable = (project_id, variable_id, accessToken) => {
    return axios.post(`${checkApiUrl()}/api/projects/${project_id}/variable/${variable_id}/share`, {
        project_id,
        variable_id
    },
    {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const postUnShareVariable = (project_id, variable_id, accessToken) => {
    return axios.post(`${checkApiUrl()}/api/projects/${project_id}/variable/${variable_id}/unshare`, {
          project_id,
          variable_id
      },
      {
          headers: {
              "skynix-access-token": accessToken,
          }
      })
}

export const postAddNewLead = (phone = '', email = '', name = '', source = '', comment = '', comment_file= '', accessToken) => {
    return axios.post(`${checkApiUrl()}/api/leads`, {
        phone,
        email,
        name,
        source,
        comment,
        comment_file
    },
    {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}
export const putUpdateLead = (budget, description, email, phone, start, status, lead_id, name, estimate, source, comment, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/leads/${lead_id}`, {
        "name": name,
        "source": source,
        "status": status,
        "email": email,
        "phone": phone,
        "start_date": start,
        "budget": budget,
        "estimate": estimate,
        "description": description,
        "lead_id": lead_id,
        "comment": comment
    },
    {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const getAllLeads = (query, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/v1/leads?${stringify(query, {arrayFormat: 'comma'})}`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const getLead = (id, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/leads/${id}`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    });
};

export  const getLeadManagerId = (manager_id, query, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/v1/leads?manager_id=${manager_id}&${stringify(query, {arrayFormat: 'comma'})}` , {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export  const getLeadStatus = (status, query, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/v1/leads?status=${status}&${stringify(query, {arrayFormat: 'comma'})}` , {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export  const getLeadManagerIdAndStatus = (manager_id, status, query, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/v1/leads?manager_id=${manager_id}&status=${status}&${stringify(query, {arrayFormat: 'comma'})}` , {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const postQualifyLead = (lead_id, description = '', comment = '', comment_file = '', accessToken) => {
    return axios.post(`${checkApiUrl()}/api/leads/${lead_id}/qualified`, {
            description,
            comment
            //comment_file
    },
    {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const postProspectLead = (lead_id, budget = '', start_date = '', comment = '', comment_file = '', accessToken) => {
    return axios.post(`${checkApiUrl()}/api/leads/${lead_id}/prospect`, {
            budget,
            start_date,
            comment,
            comment_file
        },
        {
            headers: {
                "skynix-access-token": accessToken,
            }
        })
}

export const postQuoteLead = (lead_id, estimate = '', comment = '', comment_file = '', accessToken) => {
    return axios.post(`${checkApiUrl()}/api/leads/${lead_id}/quoted`, {
            estimate,
            comment,
            comment_file
        },
        {
            headers: {
                "skynix-access-token": accessToken,
            }
        })
}

export const postContractLead = (lead_id, user_id = '', comment = '', comment_file = '', accessToken) => {
    return axios.post(`${checkApiUrl()}/api/leads/${lead_id}/client`, {
        user_id,
        comment,
        comment_file
    },
    {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const postCommentLead = (lead_id, comment, comment_file, accessToken) => {
    return axios.post(`${checkApiUrl()}/api/leads/${lead_id}/comments`, {
          comment,
          comment_file
      },
      {
          headers: {
              "skynix-access-token": accessToken,
          }
      })
}

export const getAllActiveUsersWithStatusClient = (accessToken) => {
    return axios.get(`${checkApiUrl()}/api/users?role=CLIENT&is_active=1`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const postKillClient = (lead_id, comment = '', comment_file = '', accessToken) => {
    return axios.post(`${checkApiUrl()}/api/leads/${lead_id}/dead`, {
            comment,
            comment_file
        },
        {
            headers: {
                "skynix-access-token": accessToken,
            }
        })
}

export const getManageSaaS = (accessToken, clientToken) => {
    return axios.get(`${checkApiUrl()}/core-api/is-saas-manage-available`, {
        headers: {
            "skynix-access-key": clientToken,
            "skynix-access-token": accessToken
        }
    })
}

export const getLeadFile = (uri, accessToken) => {
    return axios.get(`${checkApiUrl()}${uri}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const getSalaryReports = (query, accessToken, clientToken) => {
    return axios.get(`${checkApiUrl()}/api/salary-reports?${stringify(query, {arrayFormat: 'comma'})}`, {
        headers: {
            "skynix-access-token": accessToken,
            "skynix-access-key": clientToken
        }
    });
}
export const postSalaryReports = ({report_date}, accessToken) => {
    return axios.post(`${checkApiUrl()}/api/salary-reports`, {
          report_date
      },
      {
          headers: {
              "skynix-access-token": accessToken,
          }
      })
}
export const getSalaryList = (list_id, query, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/salary-reports/${list_id}/lists?${stringify(query, {arrayFormat: 'comma'})}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}
export const updateSalaryList = (newSalaryList, user_id, id, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/salary-reports/${id}/lists/${user_id}`, {
            "hospital_days"     : Number(newSalaryList.hospital_days),
            "vacation_days"     : Number(newSalaryList.vacation_days),
            "overtime_days"     : Number(newSalaryList.overtime_days),
            "bonuses"           : Number(newSalaryList.bonuses),
            "other_surcharges"  : Number(newSalaryList.other_surcharges)
        },
        {
            headers: {
                "skynix-access-token": accessToken,
            }
        }
    )
}
export const deleteSalaryListById = (list_id, id, accessToken) => {
    return axios.delete(`${checkApiUrl()}/api/salary-reports/${list_id}/lists/${id}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    })
}

export const getSalaryEditList = (listId, id, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/salary-reports/${id}/lists?listId=${listId}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    })
}

export const getFinancialReports = (query, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/financial-reports?${stringify(query, {arrayFormat: 'comma'})}`, {
        headers: {
             "skynix-access-token": accessToken
        }
    });
}

export const getFinancialReportEdit = (id, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/financial-reports/${id}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const putFinancialReportEdit = (value, id, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/financial-reports/${id}`, {
        ...value
    },
    {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const getFinancialReportIncome = (query, id, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/v1/financial-reports/${id}/income?${stringify(query, {arrayFormat: 'comma'})}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const deleteFinancialReportIncome = (id, income_id, accessToken) => {
    return axios.delete(`${checkApiUrl()}/api/financial-reports/${id}/income/${income_id}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const postFinancialReportAddIncome = ({amount, description, developers, from_date, projects, to_date}, id, accessToken) => {
    return axios.post(`${checkApiUrl()}/api/financial-reports/${id}/income`, {
        amount: Number(amount),
        description,
        developer_user_id: Number(developers.value),
        from_date,
        project_id: Number(projects.value),
        to_date
    },
      {
          headers: {
              "skynix-access-token": accessToken
          }
      });
}

export const getInfoBonuses = (id, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/v1/financial-reports/${id}/bonuses`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}


export const getAllProjects = (accessToken) => {
    return axios.get(`${checkApiUrl()}/api/projects?limit=1000`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const putLockFinancialReport = (id, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/financial-reports/${id}/lock`, {

    },
    {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const putPreLockFinancialReport = (id, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/financial-reports/${id}/prelock`, {

      },
      {
          headers: {
              "skynix-access-token": accessToken
          }
      });
}

export const putUnPreLockFinancialReport = (id, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/financial-reports/${id}/unprelock`, {

      },
      {
          headers: {
              "skynix-access-token": accessToken
          }
      });
}

export const getFeedbacksFinancialReport = (id, query, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/v1/feedback/${id}?${stringify(query, {arrayFormat: 'comma'})}`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    });
}

export const getInvoicesIsWithdrawn = (accessToken) => {
    return axios.get(`${checkApiUrl()}/api/invoices?is_withdrawn=false`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    });
}

export const getEmailTemplates = (accessToken) => {
    return axios.get(`${checkApiUrl()}/api/email-templates`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    });
}

export const putEmailTemplate = (template_id, subject, reply_to, body, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/email-templates/${template_id}`, {
          subject,
          reply_to,
          body,
      },
      {
          headers: {
              "skynix-access-token": accessToken,
          }
      });
}

export const getBankAccountData = (userId, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/users/${userId}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}
export const putBankAccount = (bankAccountData, userId, accessToken) => {
    const {first_name, last_name, email, role, bank_account_en} = bankAccountData;
    return axios.put(`${checkApiUrl()}/api/users/${userId}`,
      {
          first_name,
          last_name,
          email,
          role,
          bank_account_en
      },
      {
          headers: {
              "skynix-access-token": accessToken,
          }
      }
    );
}

export const updateBankAccountData = (newBankAccountData, userId, accessToken) => {
    const {bank_bic, bank_account, bank_country, bank_address, bank_city, bank_postcode, registration_date, registration_no} = newBankAccountData;

        return axios.put(`${checkApiUrl()}/api/users/${userId}`, {
              bank_bic,
              bank_account,
              bank_country,
              bank_address,
              bank_city,
              bank_postcode,
              registration_date,
              registration_no
          },
          {
              headers: {
                  "skynix-access-token": accessToken,
              }
          }
        );
    }

export const putAccount = (id, {first_name, last_name, phone, skills, about}, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/users/${id}`,
      {
          first_name,
          last_name,
          phone,
          tags: skills,
          about,
      },
      {
          headers: {
              "skynix-access-token": accessToken,
          }
      }
    );
}

export const getUserPhoto = (id, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/users/${id}/photo`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const postUserPhoto = (value, accessToken) => {
    return axios.post(`${checkApiUrl()}/api/users/photo`, {
        photo: value
    }, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const getSignPhoto = (id, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/users/${id}/sign`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const postSignPhoto = (value, accessToken) => {
    return axios.post(`${checkApiUrl()}/api/users/sign`, {
        sing: value
    }, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const getYearlyStatistics = (query, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/v1/financial-reports/yearly?${stringify(query, {arrayFormat: 'comma'})}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const getYearlyStatisticsBonuses = (year, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/users/bonuses?year=${year}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const getInvoices = (query, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/invoices?${stringify(query, {arrayFormat: 'comma'})}&order[id]=DESC`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const getInvoicesId = (id, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/invoices/${id}`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const postInvoice = (id, invoiceId, {parties}, accessToken) => {
    return axios.post(`${checkApiUrl()}/api/financial-reports/${id}/withdraw-invoice/${invoiceId}`, {
        parties,
    },
    {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const patchInvoiceWithdraw = (id, accessToken) => {
    return axios.patch(`${checkApiUrl()}/api/invoices/${id}/withdraw`,
    {},
    {
        headers: {
            "skynix-access-token": accessToken,
        }
    });
}

export const getMethods = (id = 1, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/businesses/${id}/methods`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const getDownloadPDFInvoices = (id, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/invoices/${id}/download`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const putPaidInvoices = (id, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/invoices/${id}/paid`,
      {},
      {
        headers: {
            "skynix-access-token": accessToken
        }
      }
    );
}

export const getCustomers = (query, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/users?roles[]=CLIENT&${stringify(query, {arrayFormat: 'comma'})}`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const getCustomerProjects = (query, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/projects?${stringify(query, {arrayFormat: 'comma'})}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const postInvoices = ({ discount, exchange_rate, note, subtotal, total, ...values }, accessToken) => {
    return axios.post(`${checkApiUrl()}/api/invoices`,
      {
        business_id: values.select_business.value,
        currency: values.select_currency.value,
        payment_method_id: values.select_method.value,
        project_id: values.select_projects.value,
        user_id: values.select_customer.value,
        date_end: moment(values.date_end).format('YYYY-MM-DD'),
        date_start: moment(values.date_start).format('YYYY-MM-DD'),
        discount,
        exchange_rate,
        note,
        subtotal,
        total
      },
      {
          headers: {
              "skynix-access-token": accessToken
          }
      }
    );
}

export const putInvoice = ({ customer, payment_method_id, select_currency, start_date, end_date, subtotal, discount, total, notes, vat_value, exchange_rate }, id, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/invoices/${id}`,
      {
          user_id: customer.id,
          payment_method_id,
          currency: select_currency.value,
          date_start: moment(start_date).format("YYYY-MM-DD"),
          date_end: moment(end_date).format("YYYY-MM-DD"),
          subtotal,
          discount,
          total,
          note: notes,
          exchange_rate,
          vat_value,
      },
      {
          headers: {
              "skynix-access-token": accessToken
          }
      }
    );
}

export const deleteInvoices = (id, accessToken) => {
    return axios.delete(`${checkApiUrl()}/api/invoices/${id}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const getInvoice = (id, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/invoices/${id}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const getVacationsHistory = (query, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/vacation-history-items?${stringify(query, {arrayFormat: 'comma'})}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const getUsers = (query, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/users?${stringify(query, {arrayFormat: 'comma'})}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const deleteUser = (id, accessToken) => {
    return axios.delete(`${checkApiUrl()}/api/users/${id}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const putActivateUser = (id, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/users/${id}/activate`, {},
      {
          headers: {
              "skynix-access-token": accessToken
          }
      }
    );
}

export const putDeactivateUser = (id, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/users/${id}/deactivate`, {},
      {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const postDelaySalaryUser = ({month, salary}, user_id, accessToken) => {
    return axios.post(`${checkApiUrl()}/api/delayed-salary`, {
        month,
        user_id,
        value: Number(salary)
      },
      {
          headers: {
              "skynix-access-token": accessToken
          }
      });
}

export const postCreateUser = ({first_name, last_name, company, email, middle_name, official_salary, phone, selectRoles, salary, slack_username, selectEmployment}, accessToken) => {
    return axios.post(`${checkApiUrl()}/api/users`, {
          first_name,
          last_name,
          company,
          email,
          middle_name,
          official_salary,
          phone,
          role: selectRoles.value,
          employment: selectEmployment.value,
          salary,
          slack_username
      },
      {
          headers: {
              "skynix-access-token": accessToken
          }
      });
}

export const getUser = (id, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/users/${id}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const putEditUser = ({first_name, last_name, company, email, middle_name, official_salary, phone, selectRoles, salary, slack_username, password, selectAuthType, selectEmployment}, id, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/users/${id}`, {
          auth_type: selectAuthType.value,
          first_name,
          last_name,
          company,
          email,
          middle_name,
          official_salary,
          phone,
          password,
          role: selectRoles.value,
          employment: selectEmployment.value,
          salary,
          slack_username
      },
      {
          headers: {
              "skynix-access-token": accessToken
          }
      });
}

export const putUserSalary = (id, value, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/users/${id}`, {
        official_salary: value
    }, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const getWorkHistory = (id, query, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/v1/users/${id}/work-history?${stringify(query, {arrayFormat: 'comma'})}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const postWorkHistory = (id, { date_end, date_start, title, selectType }, accessToken) => {
    return axios.post(`${checkApiUrl()}/api/users/${id}/work-history`, {
        date_end: moment(date_end).format('YYYY-MM-DD'),
        date_start: moment(date_start).format('YYYY-MM-DD'),
        title,
        type: selectType?.value
      },
      {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const putActivateProject = (id, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/projects/${id}/activate`, {},
      {
          headers: {
              "skynix-access-token": accessToken
          }
      }
    );
}

export const putDeactivateProject = (id, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/projects/${id}/suspend`, {},
      {
          headers: {
              "skynix-access-token": accessToken
          }
      });
}

export const postProjectMilestones = (id, {end_date, estimated_amount, milestones_name, start_date}, accessToken) => {
    return axios.post(`${checkApiUrl()}/api/projects/${id}/milestones`, {
        end_date,
        estimated_amount,
        name: milestones_name,
        start_date
      }, {
          headers: {
              "skynix-access-token": accessToken
          }
      }
    );
}

export const putProjectMilestones = (id, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/projects/${id}/milestones`, {},
      {
          headers: {
              "skynix-access-token": accessToken
          }
      }
    );
}

export const postCreateSalaryList = (id, {bonuses, hospital_days, other_surcharges, overtime_days, selectEmployee, vacation_days}, accessToken) => {
    return axios.post(`${checkApiUrl()}/api/salary-reports/${id}/lists`, {
        bonuses,
        hospital_days,
        other_surcharges,
        overtime_days,
        user_id: selectEmployee.value,
        vacation_days
      }, {
          headers: {
              "skynix-access-token": accessToken
          }
      }
    );
}

export const postCreateReport = ({selectProject, date_report, task, hours}, accessToken) => {
    return axios.post(`${checkApiUrl()}/api/reports`, {
          project_id: selectProject.value,
          date_report: moment(date_report).format('YYYY-MM-DD'),
          task,
          hours
      }, {
          headers: {
              "skynix-access-token": accessToken
          }
      }
    );
}

export const getReports = (query, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/reports?${stringify(query, {arrayFormat: 'comma'})}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const deleteReport = (id, accessToken) => {
    return axios.delete(`${checkApiUrl()}/api/reports/${id}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const putReport = (id, {task, hours, project_id}, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/reports/${id}`, {
        task,
        hours: Number(hours),
        project_id
    }, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const getSettings = (query, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/v1/settings?${stringify(query, {arrayFormat: 'comma'})}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const putSettings = (key, updateData, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/settings/${key}`, {
        value: updateData.value,
    }, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const getFinancialActivity = (id, query, accessToken) => {
    if(!!id) {
        return axios.get(`${checkApiUrl()}/api/reviews/${id}?${stringify(query, {arrayFormat: 'comma'})}`, {
            headers: {
                "skynix-access-token": accessToken
            }
        });
    } else {
        return axios.get(`${checkApiUrl()}/api/v1/reviews?${stringify(query, {arrayFormat: 'comma'})}`, {
            headers: {
                "skynix-access-token": accessToken
            }
        });
    }
}

export const getReviews = (id, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/monthly-reviews/${id}?order[date_from]=DESC`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const getManagementDownloadPDF = (query, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/reports/download-pdf?${stringify(query, {arrayFormat: 'comma'})}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const getManagement = (query, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/reports?${stringify(query, {arrayFormat: 'comma'})}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const putManagementApprove = (id, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/reports/${id}/approve`, {

    }, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const putManagementDisapprove = (id, hours, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/reports/${id}/disapprove`, {
        hours
      },{
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const getBusinesses = (query, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/v1/businesses?${stringify(query, {arrayFormat: 'comma'})}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const getDirectors = (accessToken) => {
    return axios.get(`${checkApiUrl()}/api/users?is_active=1&roles[]=ADMIN&roles[]=CLIENT&roles[]=FIN&roles[]=SALES&limit=1000` , {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const postBusinesses = ({address, selectDirector, is_default, name}, accessToken) => {
    return axios.post(`${checkApiUrl()}/api/businesses`, {
        address,
        director_id: selectDirector.value,
        is_default: is_default ? '1' : '0',
        name
    }, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const deleteBusinesses = (id, accessToken) => {
    return axios.delete(`${checkApiUrl()}/api/businesses/${id}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const getBusiness = (id, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/businesses/${id}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const putBusiness = (id, {address, selectDirector, is_default, name}, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/businesses/${id}`, {
        address,
        director_id: selectDirector.value,
        is_default: is_default ? '1' : '0',
        name
    }, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const getBusinessPaymentMethods = (id, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/businesses/${id}/methods`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const postBusinessPaymentMethods = (id, {address, address_alt, bank_information, bank_information_alt, business_id, is_default, name, name_alt, represented_by, represented_by_alt}, accessToken) => {
    return axios.post(`${checkApiUrl()}/api/businesses/${id}/methods`, {
        address,
        address_alt,
        bank_information,
        bank_information_alt,
        business_id,
        is_default,
        name,
        name_alt,
        represented_by,
        represented_by_alt
    }, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const deletePaymentMethod = (id, deletedId, accessToken) => {
    return axios.delete(`${checkApiUrl()}/api/businesses/${id}/methods/${deletedId}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const getBusinessPaymentMethod = (id, method_id, accessToken) => {
    return axios.get(`${checkApiUrl()}/api/businesses/${id}/methods/${method_id}`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const putBusinessPaymentMethods = (id, method_id, {address, address_alt, bank_information, bank_information_alt, business_id, is_default, name, name_alt, represented_by, represented_by_alt}, accessToken) => {
    return axios.put(`${checkApiUrl()}/api/businesses/${id}/methods/${method_id}`, {
        address,
        address_alt,
        bank_information,
        bank_information_alt,
        business_id: id,
        is_default,
        name,
        name_alt,
        represented_by,
        represented_by_alt
    }, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const getBusinessLogo = async (id, accessToken) => {
    return await axios.get(`${checkApiUrl()}/api/businesses/${id}/logo`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const postBusinessLogo = (id, logo, accessToken) => {
    return axios.post(`${checkApiUrl()}/api/businesses/${id}/logo`, {
        logo
    }, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const getResources = (accessToken) => {
    return axios.get(`${checkApiUrl()}/api/resources?limit=1000&start=0`, {
        headers: {
            "skynix-access-token": accessToken,
        }
    })
}

export const deleteSubscriptionProject = (id, accessToken) => {
    return axios.delete(`${checkApiUrl()}/api/projects/${id}/subscription`, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const postSubscriptionProject = (id, accessToken) => {
    return axios.post(`${checkApiUrl()}/api/projects/${id}/subscription`, {

    }, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}

export const postLoginsAs = (id, accessToken) => {
    return axios.post(`${checkApiUrl()}/api/login-as-user/${id}`, {}, {
        headers: {
            "skynix-access-token": accessToken
        }
    });
}
